import React from 'react';
import './HowItWorks.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowRightLong, faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function HowItWorks({ isMobile, onSignup }) {

    return (
        <section id="how-it-works">
            <section className={cx('how', {
                'mobile': isMobile
            })}>
                <div className="headline">How it works</div>
                <div className={cx('how-steps', {
                    'mobile': isMobile
                })}>
                    <div className="how-step">
                        <div className="step-number">1</div>
                        <div className="step-title">Priced</div>
                        <div className="step-description">
                            Utilize Lysted's state of the art pricing tools and historical data to net you top dollar for your tickets. We respond to market conditions to ensure you're never underpriced.
                        </div>
                    </div>
                    <div className="how-step step-arrow">
                        {isMobile ? (
                            <FontAwesomeIcon icon={faArrowDownLong} />
                        ) : (
                                <FontAwesomeIcon icon={faArrowRightLong} />
                        )}
                    </div>
                    <div className="how-step">
                        <div className="step-number">2</div>
                        <div className="step-title">Placed</div>
                        <div className="step-description">
                            There are over two thousand ticket websites and apps. With Lysted your tickets will be available on them all. And when they sell somewhere, they're instantly removed elsewhere.
                        </div>
                    </div>
                    <div className="how-step step-arrow">
                        {isMobile ? (
                            <FontAwesomeIcon icon={faArrowDownLong} />
                        ) : (
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        )}
                    </div>
                    <div className="how-step">
                        <div className="step-number">3</div>
                        <div className="step-title">Paid</div>
                        <div className="step-description">
                            Lysted will securely distribute your funds via bank deposit a few weeks after tickets are delivered to buyers.  Waiting months until the event takes place to receive your payment is a thing of the past.
                        </div>
                    </div>
                </div>
            </section>
            <section className={cx('how solver', {
                'mobile': isMobile
            })}>
                <div className={cx('how-problem', {
                    'mobile': isMobile
                })}>
                    <div className="how-problem-content">
                        We<span>solve</span>traditional ticket-selling hassles
                    </div>
                </div>
            </section>
            <section className={cx('how', {
                'mobile': isMobile
            })}>
                <div className={cx('how-content', {
                    'mobile': isMobile
                })}>
                    <div className="how-item left">
                        <div className="how-solution">
                            Problems
                        </div>
                        <div className="how-problem-container">
                            <span className="how-problem-item problem">Tired of waiting months for event payouts?</span>
                            <span className="how-problem-item problem">Spend hours manually updating prices?</span>
                            <span className="how-problem-item problem">Lost money on expired tickets?</span>
                            <span className="how-problem-item problem">Wasted time manually listing each ticket?</span>
                            <span className="how-problem-item problem">Unsure about competitive pricing?</span>
                        </div>
                        <div className="how-solution-ready">
                            Ready to get started?
                        </div>
                        <div className="how-solution-ready-sub">List your tickets for free and earn more with reduced fees while experiencing swift, secure payments directly to your bank account.</div>
                        <div className="btn arrow how-cta" onClick={onSignup}>
                            {'Sign up'}
                            <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                        </div>
                    </div>
                    <div className="how-item right">
                        <div className="how-solution">
                            Our solutions
                        </div>
                        <div className="how-problem-container">
                            <span className="how-problem-item">Payments are sent a few weeks after tickets are delivered to buyers.</span>
                            <span className="how-problem-item">Lysted can automate pricing for you.</span>
                            <span className="how-problem-item">Lysted boosts exposure by listing tickets across all major marketplaces simultaneously, enhancing your sales potential.</span>
                            <span className="how-problem-item">List it once through Lysted, and we'll list it everywhere else.</span>
                            <span className="how-problem-item">List your tickets for free, and once they sell benefit from competitive rates that we've earned over years of partnership with the major ticket exchanges.</span>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default HowItWorks;
