import React, { memo } from 'react';
import './TitleSection.scss';
import RotatingWord from './RotatingWord';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function TitleSection({ isMobile, onSignup }) {
    return (
        <section className={cx('title-section', {
            'mobile': isMobile
        })}>
            <RotatingWord isMobile={isMobile} />
            <p>We help you gain peace of mind by taking care of the complexities of listing and managing your tickets. Get your tickets priced-to-market on Vivid Seats, Stubhub, Seatgeek, Ticketmaster+, and thousands of the world's best marketplaces.</p>
            <div className="title-cta" onClick={onSignup}>
                <div className="btn arrow">
                    {'Sign up'}
                    <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                </div>
                <Link className="btn transparent accent no-display">
                    <FontAwesomeIcon size="lg" icon={faCirclePlay} className="play-icon" />
                    {'Watch video'}
                </Link>
            </div>
        </section>
    );
}

export default memo(TitleSection);
