import React, { useState, useLayoutEffect, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";
import App from './modules/App';
import Terms from './modules/Terms';
import Privacy from './modules/Privacy';
import Signup from './modules/Signup';
import { IntercomProvider } from 'react-use-intercom';
import 'react-tooltip/dist/react-tooltip.css';
import Header from './common/components/Header';
import Footer from './common/components/Footer';
import Helmet from './common/components/Helmet';
import LogIn from './common/components/LogIn';
import { useMediaQuery } from 'react-responsive';
import { isServerRender, useUTMParams } from './utils';
import { scroller } from 'react-scroll';
import * as Sentry from "@sentry/react";

function AppWrapper({ isIntercomOpen }) {
  const location = useLocation();
  const { isSignupRequested } = useUTMParams(location);

  useLayoutEffect(() => {
    if (!location?.hash) {
      document?.documentElement.scrollTo(0, 0);
    } else {
      const element = location?.hash?.replace('#', '');
      if (element) {
        scroller.scrollTo(element);
      }
    }
  }, [location]);
  
  const mediaQuery = {
    isDesktopOrLaptop: useMediaQuery({ query: '(min-width: 1224px)' }),
    isBigScreen: useMediaQuery({ query: '(min-width: 1824px)' }),
    isTabletOrMobile: useMediaQuery({ query: '(max-width: 1224px)' }),
    isPortrait: useMediaQuery({ query: '(orientation: portrait)' }),
    isRetina: useMediaQuery({ query: '(min-resolution: 2dppx)' }),
  }

  const isMobile = mediaQuery?.isTabletOrMobile;

  const navigate = useNavigate();

  const openModal = useCallback(() => {
    navigate({ pathname: '/signup', search: location?.search });
  }, [navigate, location]);

  const closeModal = useCallback(() => {
    navigate({ pathname: '/', search: location?.search });
  }, [navigate, location]);

  useEffect(() => {
    if (isSignupRequested) {
      openModal();
    }
  }, [isSignupRequested, openModal]);

  return (
    <>
      <div id="lysted-app" className="app">
        {process.env.NODE_ENV === 'production' && !isServerRender && (
          <Helmet />
        )}
        <Header
          onSignup={openModal}
          isMobile={isMobile}
          isIntercomOpen={isIntercomOpen}
          location={location}
        />
        <Routes>
          <Route path="/terms" element={<Terms isMobile={isMobile} />} />
          <Route path="/privacy" element={<Privacy isMobile={isMobile} />} />
          <Route path="/login" element={<LogIn isMobile={isMobile} location={location} asRoute />} />
          <Route path="/auth/logout" element={<LogIn isMobile={isMobile} location={location} asRoute shouldLogout />} />
          <Route path="/signup" element={<Signup isMobile={isMobile} />} />
          <Route
            exact
            path="/"
            element={(
              <App
                onOpenModal={openModal}
                onCloseModal={closeModal}
                isMobile={isMobile}
              />
            )}
          />
        </Routes>
        <Footer onSignup={openModal} isMobile={isMobile} />
      </div>
    </>
  );
}

function Main() {
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);

  function handleIntercomClose() {
    setIsIntercomOpen(false);
  }

  function handleIntercomOpen() {
    setIsIntercomOpen(true);
  }

  return (
    <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID}
        autoBoot={true}
        onHide={handleIntercomClose}
        onShow={handleIntercomOpen}
      >
        <AppWrapper isIntercomOpen={isIntercomOpen} />
      </IntercomProvider>
  );
}

Sentry.init({
  dsn: "https://ddb8fad0264396eade73992184e11018@o4505828479270912.ingest.sentry.io/4505828482875392",
  environment: process.env.NODE_ENV || 'development',
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    "enabledFeatures",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Failed fetch and/or netowrk errors
    'Failed to fetch',
    'TypeError: Cancelled',
    'Error: Cancelled',
    'cancelled',
    'NetworkError when attempting to fetch resource',
    'Network request failed',
    'The network connection was lost',
    'The internet connection appears to be offline',
    'The request timed out',
    'Load failed',
    'synthetic response',
    // Random Firebase iOS errors
    `Can't find variable: setiosparameters`,
    'setIOSParameters',
    // Firefox error with video seeking, see https://bugzilla.mozilla.org/show_bug.cgi?id=1507193
    /AbortError:*/,
    // Video auto-play errors
    /NotSupportedError:*/,
    'NotSupportedError',
    /NotAllowedError:*/,
    'NotAllowedError',
    // Random security errors
    /SecurityError:*/,
    'SecurityError',
    // MSFT errors https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/25
    'Object Not Found Matching Id',
    'Non-Error promise rejection captured with value',
    // Instagram errors
    `Can't find variable: _AutofillCallbackHandler`,
    // IG/Facbook Autofill issue https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
    `Can't find variable: PaymentAutofillConfig`,
    // Apple Mail & WebKit flakiness
    `Unexpected identifier 'A'. Expected ')'`,
    `ogySdkMraidGateway`,
    // Random errors
    'ceCurrentVideo.currentTime',
    'solana',
    'currentTime',
    'IntersectionObserver',
    'ethereum',
    `Unexpected token 'G'`

  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:/i,
    /^bpm:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /js\.intercomcdn\.com\//i,
    /js\.hubspot\.com\//i,
    /googletagmanager\.com\/gtag\/js\?id=G-VPKQSBY7SG/i
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <Main />
    </Router>
  </React.StrictMode>
);
