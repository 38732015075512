import React from 'react';
import './Footer.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function Footer({ isMobile, onSignup, location }) {

    const currentYear = new Date().getFullYear();

    return (
        <section className={cx('footer', {
            'mobile': isMobile
        })}>
            <div className="footer-background-mask">
                <div className="footer-background" />
            </div>
            <div className="footer-content">
                <div className="footer-block align-bottom">
                    <span className="copyright">
                        ©{currentYear} Lysted by <Link
                            className="link"
                            to="https://automatiq.com"
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >Automatiq</Link>.
                        <br />
                        All rights reserved.
                    </span>
                </div>
                <div className="footer-block footer-navigation">
                    <div className="footer-naviation-header">Resources</div>
                    <ul>
                        <li>
                            <Link
                                to={'https://resources.lysted.com'}
                            >
                                Blog
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'https://help.lysted.com/en/articles/8809352-terms-of-service'}
                            >
                                Terms of service
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={{
                                    pathname: '/privacy',
                                    search: location?.search
                                }}
                            >
                                Privacy policy
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'https://share.hsforms.com/1AEBuOgZMQYW2BcIXdVGIMwnnabm'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                Become a Lysted Influencer
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-block footer-navigation">
                    <div className="footer-naviation-header">Follow us</div>
                    <ul>
                        <li>
                            <Link
                                to={'https://www.tiktok.com/@itslysted'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                TikTok
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'https://www.instagram.com/lysted/'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                Instagram
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={'https://twitter.com/itslysted'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                X (formerly Twitter)
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-block cta">
                    <div className="btn arrow" onClick={onSignup}>
                        {'Get Lysted today'}
                        <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;
