import React, { memo } from "react";
import { Helmet as HeaderHelmet } from "react-helmet";

const Helmet = () => {
    return (
        <HeaderHelmet>
            <script id="facebook-pixel-script">
                {`!function (f, b, e, v, n, t, s) {
                        if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '6722521571139946');
                fbq('init', '1708418376270746');
                fbq('track', 'PageView');`}
            </script>
            <script id="reddit-pixel-script">
                {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_axlbry2iz', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');`}
            </script>
        </HeaderHelmet>
    );
};

export default memo(Helmet);