import React from 'react';
import './Value.scss';
import cx from 'classnames';

function Value({ isMobile }) {
    return (
        <section className={cx('value', {
            'mobile': isMobile
        })}>
            <div className="value-background-mask">
                <div className="value-background" />
            </div>
            <div className="value-content">
                <div className="headline">Value</div>
                <div className="value-description-container">
                    <div className="left">
                        <section className="value-section">
                            <div className="value-section-headline">Sell with the industry leading consignment platform</div>
                            <div className="value-section-description">
                                You don't need to be a professional to use Lysted, we sell tickets for all kinds of people!  Everyone from fans who can't attend events, season seat holders and professional ticket brokers trust Lysted to sell their seats.
                            </div>
                        </section>
                    </div>
                    {!isMobile && (
                        <div className="right">
                        <div className="value-animation-container">
                            <img src="/assets/login/img/concert-crowd.jpg" alt="Concert" aria-hidden="true" />
                        </div>
                    </div>
                    )}
                </div>
                <div className="metrics-container">
                    <div className="metric">
                        <span className="metric-value">5M+</span>
                        <span className="metric-description">We've sold over 5 million tickets for our customers.</span>
                    </div>
                    <div className="metric">
                        <span className="metric-value">30K+</span>
                        <span className="metric-description">Over 30,000 people trust Lysted to sell their seats.</span>
                    </div>
                    <div className="metric">
                        <span className="metric-value">$900M+</span>
                        <span className="metric-description">Lysted has distributed over $900 million via direct deposit.</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Value;
