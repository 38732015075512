import React from 'react';
import './Features.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function Features({ isMobile, onSignup }) {
    return (
        <section id="features" className={cx('features', {
            'mobile': isMobile
        })}>
            <div className="features-wrapper">
                <div className="title">Features</div>
                <div className={cx('features-content', {
                    'mobile': isMobile
                })}>
                    <div className="features-grid-item">
                        <span className="headline">Your Listing = Everywhere</span>
                        <span className="sub">Maximize your reach</span>
                        <span className="description">
                            {"At Lysted we showcase your tickets on thousands of websites simultaneously, ensuring unparalleled exposure."}
                            <br /><br />
                            {"When your tickets sell on one platform we immediately remove them from all others, eliminating the stress and financial burden of accidentally selling your tickets to multiple buyers."}
                        </span>
                    </div>
                    <div className="features-grid-item">
                        <span className="headline">100% Free to List</span>
                        <span className="sub">Amplify your profits</span>
                        <span className="description">
                            {"Say goodbye to upfront costs and hello to limitless potential. Listing your tickets on Lysted is absolutely free, allowing you to maximize your earnings."}
                            <br /><br />
                            {"We only earn a fee when your tickets sell, and our reduced rates and valuable partnerships with major ticket exchanges translate into more money in your pocket."}
                        </span>
                    </div>
                    <div className="features-grid-item">
                        <span className="headline">Best-in-Class Support</span>
                        <span className="sub">Guiding you every step of the way</span>
                    <span className="description">
                            {"When you partner with Lysted, you gain access to industry leading support that stands out from the rest. We're by your side seven days a week, ready to assist you in selling your tickets."}
                            <br /><br />
                            {"Need a helping hand? Reach out to us via email or chat directly from your Lysted account at any time."}
                        </span>
                    </div>
                    <div className="features-grid-item">
                        <span className="headline">Get Paid Faster</span>
                        <span className="sub">Unlock financial freedom</span>
                        <span className="description">
                            {"Why wait for the event to be over to receive your hard-earned funds? At Lysted, we prioritize your financial well-being by passing along our preferred payment terms with ticket exchanges."}
                            <br /><br />
                            {"Just a few weeks after delivering your tickets to buyers, we ensure that your payments are swiftly and securely deposited directly into your bank account."}
                            <br /><br />
                            {"Experience the liberation of faster payouts and the ability to plan ahead with confidence."}
                        </span>
                    </div>
                    <div className="features-grid-item">
                        <span className="headline">Automated VIP Treatment</span>
                        <span className="sub">Tailored to your preferences</span>
                        <span className="description">
                            {"Welcome to the future of ticket-selling. Our user-friendly interface puts you in control of every aspect, from inventory management to payments."}
                            <br /><br />
                            {"Our proprietary technology takes center stage, automating the entire ticket selling process, should you choose to embrace it."}
                            <br /><br />
                            {"We can automate the listing, pricing and delivering your tickets. It’s the perfect balance between manual control and effortless efficiency, empowering you to sell tickets on your own terms."}
                        </span>
                    </div>
                    <div className="features-grid-item transparent">
                        <div className="features-cta-container">
                            <div className="btn arrow" onClick={onSignup}>
                                {'Sign up'}
                                <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;
