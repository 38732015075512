import React from 'react';
import './Navigation.scss';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import { useIntercom } from 'react-use-intercom';
import LogIn from '../../../LogIn';
import cx from 'classnames';

function Navigation({ isMobile, onSignup, isIntercomOpen, onCloseMenu, location }) {

    const { show, hide } = useIntercom();

    const shouldUseScrollLink = ['/terms', '/privacy', '/signup', '/login'].includes(location?.pathname);

    const navigationElements = [
        {
            label: 'How it works',
            target: 'how-it-works',
            to: './',
            type: 'scroll',
            spy: true,
            smooth: true,
            offset: -80,
            duration: 500,
            className: 'nav-element',
            activeClass: 'active',
        },
        {
            label: 'Features',
            target: 'features',
            to: './',
            type: 'scroll',
            spy: true,
            smooth: true,
            offset: -80,
            duration: 500,
            className: 'nav-element',
            activeClass: 'active',
        },
        {
            label: 'Pricing',
            target: 'pricing',
            to: './',
            type: 'scroll',
            spy: true,
            smooth: true,
            offset: -80,
            duration: 500,
            className: 'nav-element',
            activeClass: 'active',
        },
        {
            label: 'FAQ',
            target: 'https://help.lysted.com/en/articles/8283282-frequently-asked-questions',
            blank: true,
            type: 'link',
            className: 'nav-element',
        },
        {
            label: 'Blog',
            target: 'https://resources.lysted.com',
            blank: true,
            type: 'link',
            className: 'nav-element',
        },
        {
            label: 'Support',
            target: '.',
            type: 'func',
            className: 'nav-element',
            onClick: () => {
                if (isIntercomOpen) {
                    hide();
                } else {
                    show();
                }
                if (isMobile) {
                    onCloseMenu();
                }
            },
            isActive: isIntercomOpen,
        },
    ];

    const handleSignup = () => {
        onCloseMenu();
        onSignup();
    }

    return (
        <nav className={cx('navigation', {
            'mobile': isMobile
        })}>
            {navigationElements.map((element, index) => {
                if ((element.type === 'scroll') && !shouldUseScrollLink) {
                    // ScollLink type
                    return (
                        <ScrollLink
                            key={`nav-${index}`}
                            className={element.className}
                            activeClass={element.activeClass}
                            to={element.target}
                            spy={element.spy}
                            smooth={element.smooth}
                            offset={element.offset}
                            duration={element.duration}
                            onClick={onCloseMenu}
                        >
                            {element.label}
                        </ScrollLink>
                    )
                } else if ((element.type === 'link') || (shouldUseScrollLink && (element.type === 'scroll'))) {
                    // Link type
                    return (
                        <Link
                            key={`nav-${index}`}
                            className={cx(element.className, {
                                'active': element.isActive
                            })}
                            to={element.type === 'link' ? element.target : `${element.to}#${element.target}`}
                            target={element.blank ? '_blank' : null}
                            rel={element.blank ? 'noopener noreferrer' : null}
                            onClick={onCloseMenu}
                        >
                            {element.label}
                        </Link>
                    )
                }

                // Function type
                return (
                    <div
                        key={`nav-${index}`}
                        className={cx(element.className, {
                            'active': element.isActive
                        })}
                        onClick={element.onClick}
                    >
                        {element.label}
                    </div>
                )
            })}
            {isMobile && (
                <>
                    <LogIn
                        className="nav-button"
                        onClick={onCloseMenu}
                    />
                    <div
                        onClick={handleSignup}
                        className="nav-button"
                    >
                        Sign Up
                    </div>
                </>
            )}
        </nav>
    );
}

export default Navigation;
